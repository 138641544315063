//console.log(`I'm a silly entry point`);

//const arr = [1, 2, 3];
//const iAmJavascriptES6 = () => console.log(...arr);
//window.iAmJavascriptES6 = iAmJavascriptES6;
require('intersection-observer');
import App from "./App";
import "./header.css";
import "./main.css";
import "./opis.css";
import "./polityka.css";
import "./kontakt.css";
import "./cookiesPanel.css";
//import './style.scss';
//import image from './images/test.jpg';

//import 'bootstrap';

//import 'bootstrap/dist/css/bootstrap.min.css';

import './func';

if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
          console.log('SW registered: ', registration);
        }).catch(registrationError => {
          console.log('SW registration failed: ', registrationError);
        });
      });
    }